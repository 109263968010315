<template>
    <v-input
      v-model="input"
      :id="name"
      :label="$t('stbserial')"
      :hint="
        hint != null ? hint : multiple ? $t('multiple numbers possible') : ''
      "
      :error-messages="errorMessages"
      :disabled="disabled"
      :prepend-icon="prependIcon"
      :persistent-hint="hint != null ? true : false"
    >
      <template v-slot:default>
        <v-chip-group column class="ml-4">
          <tv-list-input-chip
            v-for="(phone, i) in items"
            :key="i"
            :index="i"
            :items="items"
            :maxLength="
              maxLength != null && maxLength != '' ? Number(maxLength) : null
            "
            :selected.sync="selected"
            :disabled="disabled"
            @commit="commitItem(i, $event)"
            @delete="deleteItem($event)"
            @unselect="unselect($event)"
          />
          <v-btn
            v-if="
              !disabled && selected == null && (items.length == 0 || multiple)
            "
            @click="
              items.push('');
              selected = items.length - 1;
            "
            fab
            x-small
            depressed
          >
            <v-icon>{{ appendIcon }} </v-icon>
          </v-btn>
        </v-chip-group>
      </template>
    </v-input>
</template>

<script>
import TvListInputChip from "./TvListInputChip";

export default {
  name: "TvListInput",
  components: {
    TvListInputChip,
  },
  props: {
    errorMessages:{
      default: [],
    },
    value: {
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "stbserial",
    },
    hint: {
      type: String,
      required: false,
      default: null,
    },
    maxLength: {
      validator: (prop) =>
        typeof prop === "string" || typeof prop === "number" || prop === null,
      required: false,
      default: null,
    },
    array: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: "mdi-plus",
    },
  },
  data: () => ({
    items: [],
    input: "",
    selected: null,
  }),
  computed: {
    currentLength() {
      return this.items.join(this.separator).length;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) this.items = value;
      },
    },
    items(value) {
      this.input = value;
    },
    selected(value) {
      if (this.selected == null)
        this.items = this.items.filter(function (e) {
          return (e != null) & (e != "");
        });
    },
  },
  methods: {
    commitItem(i, value) {
      var items = [...this.items];
      items[i] = value;
      this.items = items;
      this.selected = null;
      this.$emit("input", this.items);
    },
    deleteItem(i) {
      var items = [...this.items];
      items.splice(i, 1);
      this.items = items;
      this.selected = null;
      this.$emit("input", this.items);
    },
    unselect(i) {
      this.selected = null;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "stbserial": "Set-Top Box Serial Number",
    "multiple numbers possible": "multiple numbers possible"
  },
  "de": {
    "stbserial": "Set-Top Box Serial Nummer",
    "multiple numbers possible": "mehrere Nummern möglich"
  }
}
</i18n>
