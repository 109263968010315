<script>
export default {
  name: "WikiContent",
  props: {
    slug: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    hash: {
      type: String,
      default: "",
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
  render(createElement) {
    return createElement("div", {
      attrs: {
        id: "wiki",
      },
      domProps: {
        innerHTML: this.content,
      },
    });
  },
  methods: {
    fixAnchors() {
      var that = this;
      const anchors = this.$el.getElementsByTagName("a");
      for (var i = 0; i < anchors.length; i++) {
        var anchor = anchors[i];
        const isBackendWikiUrl = (anchor.href || "").indexOf(this.baseUrl) == 0;
        if (isBackendWikiUrl) {
          const url = new URL(anchor.href);
          var slug = url.pathname.split("/").pop();
          var hash = url.hash;
          if (slug == this.slug)
            // mark as disabled
            anchor.style =
              "opacity: .4; pointer-events: none; cursor: default !important;";
          else {
            const data = { slug: slug, hash: hash };
            anchor.onclick = function () {
              that.$emit("loadSlug", { ...data });
            };
          }
          if (hash) anchor.href = hash;
          else anchor.href = "#";
        }
      }
    },
  },
  mounted() {
    this.fixAnchors();
    if (this.hash) {
      var id = String(this.hash).substring(1);
      var el = document.getElementById(id);
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
        });
        return;
      } else
        document.getElementById("wiki").scrollIntoView({
          behavior: "smooth",
        });
    }
  },
};
</script>

<style scoped>
#wiki >>> h1 {
  margin-bottom: 10px;
  margin-top: 20px;
}
#wiki >>> h2 {
  margin-bottom: 10px;
  margin-top: 20px;
}
#wiki >>> h3 {
  margin-bottom: 10px;
  margin-top: 20px;
}
#wiki >>> dl {
  padding: 0.5em;
}
#wiki >>> dt {
  float: left;
  clear: left;
  width: 100px;
  text-align: right;
  font-weight: bold;
  color: grey;
}
#wiki >>> dt::after {
  content: ":";
}
#wiki >>> dd {
  margin: 0 0 0 110px;
  padding: 0 0 0.5em 0;
}

#wiki >>> table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
#wiki >>> table thead tr {
  background-color: grey;
  color: #ffffff;
  text-align: center;
}
#wiki >>> td,
#wiki >>> tr {
  padding: 12px 15px;
}
#wiki >>> tbody tr {
  border-bottom: 1px solid #dddddd;
}
#wiki >>> tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
#wiki >>> tbody tr:last-of-type {
  border-bottom: 2px solid lightgrey;
}
#wiki >>> td.linenos .normal {
  color: #37474f;
  background-color: #263238;
  padding-left: 5px;
  padding-right: 5px;
}
#wiki >>> span.linenos {
  color: #37474f;
  background-color: #263238;
  padding-left: 5px;
  padding-right: 5px;
}
#wiki >>> td.linenos .special {
  color: #607a86;
  background-color: #263238;
  padding-left: 5px;
  padding-right: 5px;
}
#wiki >>> span.linenos.special {
  color: #607a86;
  background-color: #263238;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
