<template>
  <v-card class="my-4" outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{
          id == null
            ? $t("new TV subscription")
            : tvProduct
            ? tvProduct.product_name
            : ""
        }}
      </v-toolbar-title>
      <v-spacer />

      <v-tooltip top v-if="id != null">
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="loadingItem || loadingStatus || loadingOrder"
            :loading="loadingItem || loadingStatus"
            v-on="on"
            @click="performSync()"
            icon
          >
            <v-icon>mdi-database-sync-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("sync") }}</span>
      </v-tooltip>

      <v-tooltip top v-if="id != null">
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="loadingItem || loadingStatus || loadingOrder"
            :loading="loadingItem || loadingStatus"
            v-on="on"
            @click="getItem()"
            icon
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>

      <v-tooltip top v-if="id != null && tvProduct.product_id">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              $router.push({
                name: 'product-details',
                params: { productId: tvProduct.product_id },
              })
            "
            icon
          >
            <v-icon>mdi-text-box</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("jump to product") }}</span>
      </v-tooltip>
      <!-- <wiki slug="services-tv-details" /> -->
    </v-toolbar>

    <v-progress-linear
      :active="loadingItem || loadingStatus || loadingOptions"
      indeterminate
    />
    <v-card-text>
      <v-card v-if="id && tvProduct.product_id" class="mb-4" outlined>
        <v-simple-table dense class="pb-2 mt-4">
          <tbody>
            <tr>
              <th>{{ $t("state") }}</th>
              <td>
                <v-chip
                  :class="chipColor"
                  class="my-2 mr-2"
                  small
                >
                  {{ chipText }}
                </v-chip>
                <v-chip v-if="runningWorkers" class="info my-2 mr-2" small>
                  {{ $t("worker running") }}
                </v-chip>
              </td>
            </tr>

            <tr v-if="tvProduct.billing_start_date">
              <th>{{ $t("billing_start_date") }}</th>
              <td>{{ formatDate(tvProduct.billing_start_date) }}</td>
            </tr>

            <tr v-if="!!tvProduct.end_date">
              <th>{{ $t("billing_end_date") }}</th>
              <td>{{ formatDate(tvProduct.end_date) }}</td>
            </tr>

            <tr>
              <th>{{ $t("username") }}</th>
              <td>{{ tvProduct.username }}</td>
            </tr>

            <tr>
              <th>{{ $t("password") }}</th>
              <td>{{ tvProduct.password }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <validation-observer ref="obs" v-slot="{ invalid, errors }">
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col>
                <validation-provider
                  vid="product_description"
                  :name="$t('description')"
                  rules="max:4096"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-textarea
                    v-model="tvProduct.product_description"
                    :label="$t('description')"
                    :hint="$t('product description')"
                    name="product_description"
                    :disabled="forbidden"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                    auto-grow
                    rows="1"
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>

              <v-col>
                <validation-provider
                  rules="required|max:50"
                  vid="account_name_override"
                  :name="$t('account name override')"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="tvProduct.account_name_override"
                    name="account_name_override"
                    :disabled="forbidden"
                    :label="$t('account name override')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
              <v-col>
                <validation-provider
                  vid="zip_overwrite"
                  :name="$t('zip override')"
                  rules="required|min:4|max:4|numeric"
                  v-slot="{ valid, dirty, errors, classes }"
                >
                  <v-combobox
                    name="zip_override"
                    v-model="tvProduct.zip_override"
                    :label="$t('zip override')"
                    :search-input.sync="zipSearch"
                    :items="zipList"
                    item-text="postcode"
                    item-value="postcode"
                    :return-object="false"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                    :loading="zipLoading"
                    :disabled="forbidden"
                    :maxlength="4"
                    autocomplete="none"
                    type="number"
                    :clearable="!isMobile"
                    v-disabled-icon-focus
                    @click:clear="
                      tvProduct.zip_override = null;
                      zipSearch = null;
                      zipList = [];
                    "
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="item.id != null"
                          v-html="
                            maskSearchValue(
                              item.postcode + ' - ' + item.name,
                              zipSearch
                            )
                          "
                        />
                        <v-list-item-title
                          v-else
                          v-html="
                            maskSearchValue(
                              item.postcode,
                              zipSearch,
                              $t('your input')
                            )
                          "
                        />
                      </v-list-item-content>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item v-if="zipSearch">
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="$t('ZipNotFound', { search: zipSearch })"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </validation-provider>
              </v-col>
              <v-col>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                <v-switch :disabled="forbidden || loading"  v-model="tvProduct.active" :label="tvProduct.active ? $t('Account active') : $t('Account inactive')" color="success" :append-icon="tvProduct.active ? '' : 'mdi-exclamation-thick'" :error="!tvProduct.active" ></v-switch>

                </div>

              </template>
              <span>{{ $t("Deactivate the TV account for non-paying customers, for example.") }}</span>
            </v-tooltip>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <validation-provider
                  ref="serialNumberValidationProvider"
                  vid="serial_numbers"
                  :name="$t('serial numbers')"
                  v-slot="{ errors, valid, dirty, classes }"
                  rules=""
                >
                  <tv-list-input
                    name="serial_numbers"
                    v-model="tvProduct.serial_numbers"
                    :error-messages="errors"
                    multiple
                    array
                    :disabled="forbidden || loading"
                    @input="validateSerialNumbers()"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row class="d-flex flex-wrap pa-2">
                <v-card
                outlined
                class="d-flex flex-column ma-2"
                width="260"
                min-height="100"
                v-for="subscription in subscriptions"
                v-bind:key="subscription.id"
                >

                <v-toolbar flat>
                    {{ subscription.title_cart }}

                    <v-spacer></v-spacer>
                    <v-chip small
                    color="success"
                    v-if="
                      subscription.product_numbers.find((el) =>
                        tvProduct.subscription === el
                      )
                    ">
                  {{ $t("active") }}
                </v-chip>

                  </v-toolbar>

              <v-spacer class="mb-auto"></v-spacer>
              <v-card-actions v-if="!loading && !runningWorkers && !forbidden">
                <v-spacer />
                <v-btn v-if="gfoSubscription==subscription.id" @click="gfoSubscription=null" color="secondary" text>
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn v-else @click="gfoSubscription=subscription.id" :disabled="!!subscription.product_numbers.find((el) =>tvProduct.subscription === el)" text>
                  {{ $t("activate") }}
                </v-btn>
              </v-card-actions>
                </v-card>
            </v-row>

            <v-row class="d-flex flex-wrap pa-2">
                <v-card
                outlined
                class="d-flex flex-column ma-2"
                width="260"
                min-height="100"
                v-for="option in options"
                v-bind:key="option.id"
                >
                  <v-toolbar flat>
                    {{ option.title_cart }}

                    <v-spacer></v-spacer>
                    <v-chip small
                    color="success"
                    v-if="
                      option.product_numbers.find((el) =>
                        tvProduct.options.includes(el)
                      )
                    ">
                  {{ $t("active") }}
                </v-chip>

                  </v-toolbar>

                <v-spacer class="mb-auto"></v-spacer>
                <v-card-actions v-if="!loading && !runningWorkers && !forbidden">

                  <v-spacer />

                  <v-btn v-if="gfoOptionsTerminate.includes(option.id)" @click="gfoOptionsTerminate = gfoOptionsTerminate.filter((item) => item !== option.id)" color="secondary" text>
                    {{ $t("cancel") }}
                  </v-btn>

                  <v-btn v-else-if="option.product_numbers.find((el) => tvProduct.options.includes(el)) && !gfoOptionsTerminate.includes(option.id)" @click="gfoOptionsTerminate.push(option.id)" text>
                    {{ $t("deactivate") }}
                  </v-btn>

                  <v-btn v-else-if="gfoOptionsOrder.includes(option.id)" @click="gfoOptionsOrder = gfoOptionsOrder.filter((item) => item !== option.id)" color="secondary" text>
                    {{ $t("cancel") }}
                  </v-btn>

                  <v-btn v-else @click="gfoOptionsOrder.push(option.id)" :disabled="!!option.product_numbers.find((el) =>tvProduct.options.includes(el))" text>
                    {{ $t("activate") }}
                  </v-btn>

                </v-card-actions>
              </v-card>
            </v-row>

          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn @click="$emit('canceled')" v-if="id == null && embedded">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              :disabled="invalid || loading || forbidden || editSerialNumbers || (!id && !gfoSubscription)"
              :loading="loadingOrder"
              @click="submit()"
            >
              {{ id != null ? $t("save") : $t("create") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { config } from "@/config";
import formatDate from "@/utils/mixins/formatDate";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import checkWorkers from "@/utils/mixins/checkWorkers";
import TvListInput from "@/components/services/TvListInput";
import Wiki from "@/components/basics/Wiki";

const emptyProduct = {
  product_name: null,
  billing_start_date: null,
  description: null,
  blocked: null,
  active: true,
  options: [],
  username: null,
  password: null,
  account_name_override: null,
  zip_override: "",
  serial_numbers: [],
  end_date: null,
};

const addrApiClient = axios.create({
  baseURL: config.addressBackendUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  timeout: config.addressBackendTimeout || 3000,
});

addrApiClient.interceptors.request.use(
  function (cfg) {
    cfg.headers["Authorization"] = "Bearer " + config.addressBackendToken;
    return cfg;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// checks if worker would block product update
var checkWorkerCallback = function (worker) {
  let matched = [
    "workerticketzumatvupdate",
    "workerticketzumatvcreate",
    "workerticketproductblock",
    "workerticketproducttermination",
  ].includes(worker.worker_code);
  return !matched;
};

export default {
  name: "ZumaTvDetail",
  mixins: [formatDate, showErrors, isMobile, checkWorkers],
  components: {
    TvListInput,
    Wiki,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    embedded: {
      type: [Boolean],
      default: false,
    },
  },
  data: () => ({
    tvProduct: { ...emptyProduct },
    loadingItem: false,
    loadingOptions: false,
    loadingStatus: false,
    loadingOrder: false,
    loadingValidation: false,
    subscriptions: [],
    options: [],
    gfoSubscription: null,
    gfoOptionsOrder: [],
    gfoOptionsTerminate: [],
    runningWorkers: false,
    serialSearch: null,
    editSerialNumbers: false,
    zipList: [],
    zipSearch: "",
    zipLoading: false,
    addressApiAlive: true,
  }),
  watch: {
    id: {
      immediate: true,
      handler(value) {
        if (value) {
          this.tvProduct = { ...emptyProduct };
          this.getItem();
        }
      },
    },
    zipSearch(value) {
      this.searchZips(value);
    },
  },
  computed: {
    chipColor() {
      if (this.tvProduct.blocked == null) {
        return "error";
      } else if (!!this.tvProduct.end_date) {
        return "warning";
      } else if (this.tvProduct.blocked || !this.tvProduct.active) {
        return "warning";
      } else {
        return "success";
      }
    },
    chipText() {
      if (this.tvProduct.blocked == null) {
        return this.$i18n.t("malfunction");
      } else if (!!this.tvProduct.end_date) {
        return this.$i18n.t("terminated")
      } else if (this.tvProduct.blocked || !this.tvProduct.active) {
        return this.$i18n.t("inactive");
      } else {
        return this.$i18n.t("active");
      }
    },
    loading() {
      return (
        this.loadingItem ||
        this.loadingOptions ||
        this.loadingStatus ||
        this.loadingValidation ||
        this.loadingOrder
      );
    },
    forbidden() {
      return !(this.id == null || !this.runningWorkers) || !this.$store.state.session.apiRoles.includes('API-ORD');
    },
  },
  methods: {
    setEditSerialNumbers(value) {
      this.$nextTick(() => {
        this.editSerialNumbers = value;
      });
    },
    validateSerialNumbers(event) {
      if (this.tvProduct && this.tvProduct.serial_numbers) {
        var that = this;
        var valProv = that.$refs.serialNumberValidationProvider;
        var errors = [];
        var serialNumbers = [];
        for (let i = 0; i < this.tvProduct.serial_numbers.length; i++) {
          let sn = this.tvProduct.serial_numbers[i];
          serialNumbers = serialNumbers.concat(
            sn.split(/\s+/).filter(function (sn) {
              return sn && sn.trim();
            })
          );
        }
        this.loadingValidation = true;
        this.$http
          .get("services/tv/serial-numbers", {
            params: { serial_number: serialNumbers },
          })
          .then((response) => {
            response.data.forEach(function (result) {
              if (result.error != null)
                errors.push(result.serial_number + " " + result.error);
              else if (
                result.zuma_ids.length == 1 &&
                result.zuma_ids[0] != that.id
              )
                errors.push(
                  that.$i18n.t("SerialAlreadyUsed", {
                    serial_number: result.serial_number,
                  })
                );
              else if (result.zuma_ids.length > 1)
                errors.push(
                  that.$i18n.t("SerialMultipleUsed", {
                    serial_number: result.serial_number,
                  })
                );
            });
          })
          .catch((err) => {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          })
          .finally(function () {
            valProv.setErrors(errors);
            that.loadingValidation = false;
            that.setEditSerialNumbers(false, "validateSerialNumbers");
          });
        this.tvProduct.serial_numbers = serialNumbers;
      }
    },
    getItem() {
      var that = this;
      that.loadingItem = true;
      this.$http
        .get("services/tv/" + this.id)
        .then((response) => {
          that.tvProduct = response.data;

          that.tvProduct.zip_override = response.data.zip_override.toString();
          that.getWorkerStatus();
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingItem = false;
        });
    },
    performSync() {
      var that = this;
      that.loadingItem = true;
      this.$http
        .get("services/tv/" + this.id + "/sync")
        .then((response) => {
          that.tvProduct = response.data;

          that.tvProduct.zip_override = response.data.zip_override.toString();
          that.getWorkerStatus();
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingItem = false;
        });
    },
    submit() {
      var that = this;
      that.loadingOrder = true;
      this.tvProduct.serial_numbers = this.tvProduct.serial_numbers.filter(
        (sn) => {
          return sn.length > 0;
        }
      );
      this.tvProduct.serial_numbers = [
        ...new Set(this.tvProduct.serial_numbers),
      ];

      this.tvProduct.gfo_subscription = this.gfoSubscription;
      this.tvProduct.gfo_options_order = this.gfoOptionsOrder;
      this.tvProduct.gfo_options_terminate = this.gfoOptionsTerminate;

      if (that.id) {
        // update product
        this.$http
          .patch("services/tv/" + this.id, this.tvProduct)
          .then((response) => {
            that.$snotify.success(
              that.$i18n.t("workerCreated", {
                subject: response.data.subject,
              })
            );
            that.getItem();
            that.$emit("updated");
          })
          .catch((err) => {
            that.$refs.obs.setErrors(err.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(err));
          })
          .finally(function () {
            that.loadingOrder = false;
            that.gfoSubscription = null;
            that.gfoOptionsOrder = [];
            that.gfoOptionsTerminate = [];
          });
      } else {
        // create new product
        this.$http
          .post("services/tv", this.tvProduct)
          .then((response) => {
            that.$snotify.success(
              that.$i18n.t("workerCreated", {
                subject: response.data.subject,
              })
            );
            that.tvProduct = { ...emptyProduct };
            that.$emit("created");
          })
          .catch((err) => {
            that.$refs.obs.setErrors(err.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(err));
          })
          .finally(function () {
            that.loadingOrder = false;
            that.gfoSubscription = null;
            that.gfoOptionsOrder = [];
            that.gfoOptionsTerminate = [];
            that.tvProduct.serial_numbers = [];
          });
      }
    },
    getOptions() {
      var that = this;
      that.loadingOptions = true;
      this.$http
        .get("services/tv/gfo-options")
        .then((response) => {
          that.subscriptions = response.data.subscriptions.sort((a, b) => a.pos - b.pos);
          that.options = response.data.options.sort((a, b) => a.pos - b.pos);
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingOptions = false;
        });
    },
    getWorkerStatus() {
      if (!this.$store.state.session.apiRoles.includes("API-ORD")) {
        return
      }
      var that = this;
      this.loadingStatus = true;
      this.$http
        .get("products/" + this.tvProduct.product_id)
        .then((response) => {
          that.checkWorkers(
            response.data.workers,
            checkWorkerCallback,
            "runningWorkers",
            "loadingStatus"
          );
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        });
    },
    validValue(value) {
      return (
        value != null &&
        (typeof value === "string" || value instanceof String) &&
        value.trim() != ""
      );
    },
    searchZips(value) {
      var that = this;
      if (this.validValue(value) && this.addressApiAlive && !this.zipLoading) {
        this.zipLoading = true;
        return addrApiClient
          .get("postcodes", {
            params: {
              postcode: value,
            },
          })
          .then((response) => {
            that.zipList = response.data.filter(function (item) {
              return (
                !that.zipSearch ||
                (item.postcode != null &&
                  item.postcode
                    .toString()
                    .indexOf(that.zipSearch.toLowerCase()) >= 0)
              );
            });
          })
          .catch(function (error) {
            that.addressApiAlive = false;
          })
          .finally(function () {
            that.zipLoading = false;
          });
      }
    },
    maskSearchValue(value, search, suffix = "") {
      value = value || "";
      search = search || "";
      suffix = suffix || "";
      var result = value;
      if (value != "" && search != "") {
        var idx = value.toLowerCase().indexOf(search.toLowerCase());
        var p0,
          p1,
          p2 = "";
        if (idx >= 0) {
          p0 = value.slice(0, idx);
          p1 = value.slice(idx, idx + search.length);
          p2 = value.slice(idx + search.length, value.length);
          result = p0 + '<span class="list-item-mask">' + p1 + "</span>" + p2;
        }
      }
      if (this.validValue(result) && suffix != "")
        result = "<i>" + result + " - " + suffix + "</i>";
      return result;
    },
  },
  mounted() {
    this.getOptions();
  },
};
</script>

<i18n>
{
  "en": {
    "new TV subscription": "new TV subscription",
    "state": "State",
    "active": "Active",
    "inactive": "Inavtive",
    "terminated": "Terminated",
    "Account active": "Account active",
    "Account inactive": "Account inactive",
    "worker running": "ongoing process",
    "billing_end_date": "End of product",
    "reload":"Reload",
    "sync": "Perform manual synchronization",
    "activate": "Activate",
    "deactivate": "Deactivate",
    "create":"Create",
    "change": "Change",
    "cancel": "Cancel",
    "jump to product":"Open Product",
    "billing_start_date":"First billing date",
    "account name override":"Account name",
    "product description": "Product description (appears on the invoice)",
    "zip override":"ZIP",
    "username":"Username",
    "password":"Password",
    "subscription":"Subscription",
    "options":"Options",
    "serial numbers":"TV Box serial numbers",
    "serial numbers separated by newline":"Serial numbers are separated by newline",
    "server error, contact support if persists": "Server Error, please reload product later, or contact support with given error",
    "workerCreated": "worker {subject} created",
    "SerialAlreadyUsed": "{serial_number} is already being used in another subscription",
    "SerialMultipleUsed": "{serial_number} is used in multiple subscriptions",
    "ZipNotFound": "ZIP <strong>{search}</strong> not found.",
    "Deactivate the TV account for non-paying customers, for example": "Deactivate the TV account. For non-paying customers, for example."
  },
  "de": {
    "new TV subscription": "neues TV Abonnement",
    "state": "Status",
    "active": "Aktiv",
    "inactive": "Inaktiv",
    "terminated": "Gekündigt",
    "Account active": "Account aktiv",
    "Account inactive": "Account inaktiv",
    "worker running": "laufender Auftrag",
    "billing_end_date": "Produktende",
    "reload":"Neu laden",
    "sync": "Manuelle Synchronisation ausführen",
    "activate": "Aktivieren",
    "deactivate": "Deaktivieren",
    "create":"Erstellen",
    "change": "Wechseln",
    "cancel": "Abbrechen",
    "jump to product":"Produkt Öffnen",
    "billing_start_date":"Verrechnungsstart",
    "account name override":"Account Name",
    "product description": "Produkt Beschreibung (erscheint auf der Rechnung)",
    "zip override":"PLZ",
    "username":"Nutzername",
    "password":"Passwort",
    "subscription":"Abonnement",
    "options":"Optionen",
    "serial numbers":"TV Box Seriennummern",
    "serial numbers separated by newline":"Eine Seriennummer Pro Zeile",
    "server error, contact support if persists": "Server Fehler, bitte das Produkt später neuladen, oder den Support kontaktieren mit Fehlermeldung",
    "workerCreated": "Auftrag {subject} angelegt",
    "SerialAlreadyUsed": "{serial_number} wird bereits in einem anderen Abonnement verwendet",
    "SerialMultipleUsed": "{serial_number} wird in mehreren Abonnement verwendet",
    "ZipNotFound": "PLZ <strong>{search}</strong> nicht gefunden.",
    "Deactivate the TV account for non-paying customers, for example.": "Deaktiviert das TV-Konto. Für nicht zahlende Kunden, zum Beispiel."
  }
}
</i18n>
