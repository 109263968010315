/**
 * popup errors.
 */

export default {
  methods: {
    showErrors(errors, timeout = 360000) {
      var that = this;
      if (errors) {
        Object.keys(errors).forEach(function (key) {
          const title = ['detail', 'default', 'response', 'non_field_errors'].includes(key) ? that.$i18n.t("error") : key;
          const msg = errors[key].join(", ");
          that.$snotify.error(msg, title, {
            timeout: timeout,
            showProgressBar: false,
            closeOnClick: false,
            buttons: [
              {
                text: "OK",
                action: (toast) => {
                  that.$snotify.remove(toast.id);
                },
                bold: true,
              }
            ]
          });
        });
      }
    }
  }
};
