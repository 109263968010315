<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-spacer />
      <v-tooltip top v-if="$store.state.session.apiRoles.includes('API-ORD')">
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="loading || create"
            v-on="on"
            @click="
              create = true;
              expanded = [];
            "
            icon
          >
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("create") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn :disabled="loading" v-on="on" @click="getItems()" icon>
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("reload") }}</span>
      </v-tooltip>
      <wiki slug="services-tv-overview" />
    </v-toolbar>

    <v-card-text>
      <v-expand-transition v-if="$store.state.session.apiRoles.includes('API-ORD')">
        <div v-show="create">
          <zuma-tv-detail
            :embedded="true"
            @canceled="create = false"
            @created="
              create = false;
              getItems();
            "
          />
        </div>
      </v-expand-transition>

      <v-text-field
        v-model="search"
        append-outer-icon="mdi-magnify"
        clear-icon="mdi-close-circle"
        :disabled="loading"
        :clearable="!isMobile"
        v-bind:label="$t('search')"
        single-line
        hide-details
      />

      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :loading="loading"
        :search="search"
        :expanded="expanded"
        show-expand
        single-expand
        :hide-default-footer="items.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        @item-expanded="create = false"
      >
        <template v-slot:item.serial_numbers="{ item }">
          <v-chip
            v-for="(sn, index) in item.serial_numbers"
            :key="index"
            class="mt-1 mb-1 mr-1"
            outlined
            small
          >
            {{ sn }}
          </v-chip>
        </template>

        <template v-slot:item.billing_start_date="{ item }">
          <div class="monospace nowrap">
            {{
              item.billing_start_date
                ? formatDate(item.billing_start_date)
                : "-"
            }}
          </div>
        </template>

        <template v-slot:item.blocked="{ item }">
          <v-chip
            :class="getChipColor(item)"
            class="my-2 mr-2"
            small
          >
            {{ getChipText(item) }}
          </v-chip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <zuma-tv-detail :id="item.id" @updated="getItems()" />
          </td>
        </template>

        <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
          <v-btn
            v-if="item.product_name != null"
            @click="expand(!isExpanded)"
            icon
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ZumaTvDetail from "@/views/services/ZumaTvDetail";
import formatDate from "@/utils/mixins/formatDate";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "ZumaTV",
  mixins: [formatDate, isMobile],
  components: {
    ZumaTvDetail,
    Wiki,
  },
  props: {
    productId: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    loading: false,
    expanded: [],
    create: false,
    items: [],
    itemsTotal: 0,
    options: {},
    search: "",
  }),
  computed: {
    headers() {
      return [
        { text: this.$i18n.t("product name"), value: "product_name" },
        { text: this.$i18n.t("description"), value: "product_description" },
        {
          text: this.$i18n.t("account_name_override"),
          value: "account_name_override",
        },
        { text: this.$i18n.t("serial numbers"), value: "serial_numbers" },
        { text: this.$i18n.t("state"), value: "blocked" },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  methods: {
    getChipColor(item) {
      if (item.blocked == null) {
        return "error";
      } else if (!!item.end_date) {
        return "warning";
      } else if (item.blocked || !item.active) {
        return "warning";
      } else {
        return "success";
      }
    },
    getChipText(item) {
      if (item.blocked == null) {
        return this.$i18n.t("malfunction");
      } else if (!!item.end_date) {
        return this.$i18n.t("terminated")
      } else if (item.blocked || !item.active) {
        return this.$i18n.t("inactive");
      } else {
        return this.$i18n.t("active");
      }
    },
    getItems() {
      var that = this;
      that.loading = true;
      this.$http
        .get("services/tv", { params: { product_id: this.productId } })
        .then((response) => {
          that.items = response.data;
          that.itemsTotal = response.data.length;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted: function () {
    this.getItems();
  },
};
</script>

<i18n>
{
  "en": {
    "show tv details":"Open TV product",
    "reload":"Reload",
    "create":"Create TV product",
    "product name":"Product Name",
    "billing_start_date":"First billing date",
    "account_name_override": "Account name",
    "state": "State",
    "serial numbers": "Serial numbers",
    "active": "Active",
    "inactive": "Inactive",
    "malfunction": "Malfunction",
    "terminated": "Terminated",
    "description": "Description",
    "save": "Save"
  },
  "de": {
    "show tv details":"TV Produkt öffnen",
    "reload":"Neu laden",
    "create":"TV Produkt Erstellen",
    "product name":"Produkt Name",
    "billing_start_date":"Verrechnungsstart",
    "account_name_override": "Account Name",
    "state": "Status",
    "serial numbers": "Seriennummern",
    "active": "Aktiv",
    "inactive": "Inaktiv",
    "malfunction": "Defekt",
    "terminated": "Gekündigt",
    "description": "Beschreibung",
    "save": "Speichern"
  }
}
</i18n>
