import store from "@/store";

export default {
  methods: {
    formatDate: function (dateString) {
      var locale = store.state.locale == 'en' ? 'en-GB' : store.state.locale;
      return new Date(dateString).toLocaleDateString(locale, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      });
    }
  }
  // Usage:
  // import formatDate from "@/utils/mixins"
  // export default {
  //   mixins: [formatDate],
};
